/*
 * Text content
 *
 */

.text_page {
  header.text_page_header {
    text-align: center;
  }
}

figure.png,
figure.jpg,
figure.gif {
  margin: 1em 0;

  img {
    max-width: 100%;
    height: auto;
  }

  figcaption {
    display: none;
  }
}

.cms_page {
  padding-top: 2em;

  menu.side_navigation {
    @include reset_ul_li;

    line-height: 1.4;
    margin: 0;
    padding: 0;

    ul {
      li {
        display: block;
        margin: 0 0 0.5em;

        a {
          padding: 0.5em 0.75em;
          color: #fff;
          background: var(--color-base);
          display: block;
          text-decoration: none;
        }

        a:hover {
          background: rgba(var(--color-base-string), 0.9);
        }

        ul {
          background: rgba(var(--color-base-string), 0.06);
          padding: 0.75em 0.75em 0.75em 1.25em;
          font-size: 0.85rem;

          li {
            margin: 0 0 1em;

            a {
              background: initial;
              padding: 0;
              font-weight: bold;
              color: var(--color-base);
            }

            a:hover {
              background: initial;
              color: var(--color-base);
              filter: brightness(0.9);
            }

            ul {
              background: none;
              padding: 1em 0 0 1.5em;

              li {
                a {
                  font-weight: normal;
                  color: $color_text;
                }
              }
            }
          }
        }
      }
    }
  }

  article {
    @include min-screen(740) {
      padding-left: 6em;
    }

    .breadcrumb {
      margin-top: 0;
      padding-top: 0;
      font-size: 0.75em;
      opacity: 0.5;
    }

    h1 {
      font-size: $f3;
      border-bottom: 1px solid $color_main_soft;
      padding: 0 0 0.5em;
    }

    .page_attachments {
      background: rgba(var(--color-base-string), 0.06);
      width: 50%;
      float: right;
      margin: 0 0 2em 2em;
      padding: 2em;
      border-radius: 6px;

      h3 {
        margin: 0 0 1em;
        font-size: 1rem;
      }

      .file {
        clear: both;
        margin-top: -0.5em;
        margin-left: -0.5em;

        .icon {
          float: left;
          margin: 0 1rem 1rem 0;
          font-size: 2.5rem;
          opacity: 0.3;
        }

        a {
          display: block;
          text-decoration: none;
          padding: 0.5em;
          border-radius: 6px;
        }

        a:hover {
          background: rgba(var(--color-base-string), 0.06);
          filter: brightness(0.95);
        }

        h4 {
          text-transform: none;
          margin: 0;
          text-decoration: underline;
        }

        .meta {
          opacity: 0.65;
          font-size: $f7;
        }
      }
    }
  }

  img {
    width: 100%;
    height: auto;
  }
}

.page_children {
  display: flex;
  flex-wrap: wrap;
  line-height: 1.2;

  .page_child {
    background: rgba(var(--color-base-string), 0.06);
    border-radius: 6px;
    margin: 0.5em;
    font-size: $f5;
    font-weight: 600;
    box-sizing: border-box;
    width: 40%;
    flex: 2 1 auto;

    a {
      display: block;
      padding: 1em;
    }

    a:hover {
      background: rgba(var(--color-base-string), 0.06);
      filter: brightness(0.95);
    }

    .page_children {
      padding: 0 1em 1em;
      display: block;

      .page_child {
        font-weight: normal;
        font-size: 0.85rem;
        margin: 0;
        display: inline;
        background: none;
        padding: 0;

        a {
          padding: 0;
          margin: 0 0 0.5em;
        }
      }
    }
  }
}

.nowrap {
  white-space: nowrap;
}
